/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useMemo } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./HeaderSeller.css";
import { IconContext } from "react-icons/lib";

import Images from "../../../assets/uploaded_images.json";

import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { toast } from "react-toastify";

import { filtersProperty, geocodeAddressLocation } from "../../../features/property/propertySlice";
import { contractDetails } from "../../../features/common/contractSlice";
import { messageNotifications, notifications, notificationsPropertyStatusCheck, readNotifications } from "../../../features/user/authSlice";
import { resetInformationTab, resetProperty360Walk, resetVirtualTour, sellerRedirectNotification } from "../../../features/user/sellerSlice";

import { listServiceRequests, resetServiceRequest } from "../../../features/common/serviceRequestSlice";

import { validateInput } from "../../../helpers/userInputEscaper";

import { getUserLang } from "../../../hooks/useTranslator";
import { getLocalStorageItem, setLocalStorageItem } from "../../../config/localStorageEncryption";

/* Components */
import Notifications from "../../common/Notifications/Notification";
import SideBarMenu from "./headerSideBar";
import { LangSwitcher } from "../../common/LangSwitcher/LangSwitcher";
import ProperyCardIsStillActive from "../../common/PropertyCard/ProperyCardIsStillActive";
import MessageNotification from "../../common/Notifications/MessageNotification";
import { supportedLanguages } from "../../common/search/SupportedLanguages";
import ProfileMenu from "../../common/ProfileMenu/ProfileMenu";
import { dispatchPropertyOffers } from "./functions";
import CustomPlacesAutocomplete from "../../common/CustomPlacesAutocomplete";

/* Images */
const HomeOutline = Images["HomeOutline.svg"];
const Logo = Images["Logo.svg"];
const AddIconProperty = Images["add-property-icon.svg"];
const HeaderSearchIcon = Images["home-page-search-icon.svg"];
const GroupPropertyDetails = Images['GroupPropertyDetails.svg'];

const HeaderSeller = () => {
    const isTabletDevice = useMediaQuery({ query: "(max-width: 767px)" });

    const colorValue = useMemo(() => ({ color: "#fff" }), []);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const { t } = useTranslation();
    const userLanguage = getUserLang();
    const [isLangSupported, setIsLangSupported] = useState(false);
    const activeUser = getLocalStorageItem("user") ? getLocalStorageItem("user") : {};

    const storeAddress = useSelector((state) => state.propertySlice?.filters?.address);

    const personaId = getLocalStorageItem("activePersonaDetails")?.sellerPersonaId;
    const messageRef = useRef(null);
    const messageRef2 = useRef(null)
    const [address, setAddress] = useState("");
    const [openNotificaration, setOpenNotification] = useState(false);
    const [openNotificaration2, setOpenNotification2] = useState(false);
    const [isFocused, setIsFocused] = useState(false);

    useEffect(() => {
        let addr = "";

        const locPathCondition = location?.pathname === "/search-properties";

        if (address !== storeAddress && locPathCondition) {
            addr = storeAddress;
        }

        if (address !== storeAddress) {
            setAddress(addr);
        }
    }, [storeAddress]);

    useEffect(() => {
        dispatch(sellerRedirectNotification(""));

        const checkIfClickedOutside = (e) => {
            if (openNotificaration && messageRef.current && !messageRef.current.contains(e.target)) {
                setOpenNotification(false);
            }
        };

        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [openNotificaration]);

    /* Handle click search icon */
    const handleClick = () => {
        navigate("/search-properties");

        dispatch(filtersProperty({
            otherPromises: {},
            propertyTypes: [],
            propertyNeighborhood: [],
            scrollPosition: 0,
            selectCloseTo: [],
            selectConstructionStage: [],
            selectConstructionType: [],
            selectEquipment: [],
            selectFloorType: [],
            selectHeating: [],
            selectOtherPremises: [],
            valueBudget: [0, 500000],
            address: "",
            beforeSelectNeighbothood: "",
            continent: "",
            country: "",
            municipality: "",
            neighborhood: "",
            region: "",
            locationLatitude: "",
            locationLongitude: "",
            sortBy: {},
            maxSquareMeter: "",
            minSquareMeter: "",
            maxYearBuilt: "",
            minYearBuilt: "",
            originalSelectedAddress: ""
        }));

        dispatch(filtersProperty({
            scrollPosition: 0
        }))
    };

    /* Handle select cities */
    const handleSelect = async (value) => {
        let resultAddress = await geocodeAddressLocation(value);
        if (
            !resultAddress?.formatted_address &&
                !resultAddress?.latitude &&
                !resultAddress?.longitude &&
                !resultAddress?.municipality &&
                !resultAddress?.region
        ) {
            toast.error("Please choose another suitable and accurate address");
        } else {
            let newSearchQuery = {
                address: resultAddress?.formatted_address,
                originalSelectedAddress: value
            };
            if (resultAddress?.municipality) {
                newSearchQuery.municipality = resultAddress?.municipality;
            }
            else{
                newSearchQuery.municipality =""
            }

            if (resultAddress?.neighborhood) {
                newSearchQuery.neighborhood = resultAddress?.neighborhood;
            }
            else{
                newSearchQuery.neighborhood =""
            }

            if (resultAddress?.region) {
                newSearchQuery.region = resultAddress?.region;
            }
            else{
                newSearchQuery.region =""
            }

            if (resultAddress?.continent) {
                newSearchQuery.continent = resultAddress?.continent;
            }
            else{
                newSearchQuery.continent =""
            }

            if (resultAddress?.country) {
                newSearchQuery.country = resultAddress?.country;
            }
            else{
                newSearchQuery.country =""
            }

            if (resultAddress?.neighborhood) {
                newSearchQuery.neighborhoodSearchQuery = resultAddress?.neighborhood;
            }
            else{
                newSearchQuery.neighborhoodSearchQuery =""
            }
            dispatch(filtersProperty(newSearchQuery));

            setAddress("");

            navigate("/search-properties");
        }
    };

    /* Handle redirect to create property */
    const handleCreateProperty = () => {
        if (activeUser?.isRestricted) {
            dispatch(contractDetails({
                activePersona: "seller",
                isOpenModal: true,
            }));

            return toast.warning(`${t("Твоят акаунт е в процес на верифициране. Очаквай отговор скоро!")}`);
        }

        dispatch(resetInformationTab());
        dispatch(resetProperty360Walk());
        dispatch(resetVirtualTour());
        navigate("/seller/post-an-add/information");
    };

    /* Handle click notification */
    const handleClickNotification = (item) => {
        const activePersonaDetailsUserId = getLocalStorageItem("activePersonaDetails")
            ? getLocalStorageItem("activePersonaDetails")?.userId
            : "";

        let redirectUrl = "";

        if (item?.notificationType === "propertyApproved") {
            redirectUrl = `property-details?${item?.propertyId}`;
        } else if (item?.notificationType === "approvedPayCommission") {
            redirectUrl = `property-details?${item?.propertyId}`;
        } else if (item?.notificationType === "buyerPublicReview") {
            redirectUrl = `/seller/public-profile?userId=${activePersonaDetailsUserId}`;
        } else if (
            item?.notificationType === "receivedOffer" &&
            (item?.propertyId || item?.propertyOfferId)
        ) {
            setLocalStorageItem("chosenPropertyNotif", item?.propertyId);
            redirectUrl = `/seller/my-adds`;
        } else if (item?.notificationType === "buyerRequest") {
            dispatchPropertyOffers(item, dispatch, location);
        } else if (item?.notificationType === "buyerChangeDateViewing") {
            dispatchPropertyOffers(item, dispatch, location);
        } else if (item?.notificationType === "buyerAcceptViewingRequest") {
            dispatchPropertyOffers(item, dispatch, location);
        } else if (item?.notificationType === "buyerCompleteOffer") {
            dispatchPropertyOffers(item, dispatch, location);
        } else if (item?.notificationType === "buyerSeizesProperty") {
            dispatchPropertyOffers(item, dispatch, location);
        } else if (item?.notificationType === "buyerSignContract") {
            dispatchPropertyOffers(item, dispatch, location);
        } else if (item?.notificationType === "contractSigneBuyerSeller") {
            dispatchPropertyOffers(item, dispatch, location);
        } else if (item?.notificationType === "lawyerAcceptedInquiry") {
            dispatch(
                listServiceRequests({ customerId: personaId, customerType: "seller" })
            );
            dispatch(resetServiceRequest());
            redirectUrl = "/seller/profile?" + item?.senderPersona + "s";
        } else if (
            item?.notificationType === "photographerMarkvirtualTourCompleted"
        ) {
            dispatch(
                listServiceRequests({ customerId: personaId, customerType: "seller" })
            );
            dispatch(resetServiceRequest());
            redirectUrl = "/seller/profile?" + item?.senderPersona + "s";
        } else if (
            item?.notificationType === "lawyerAcceptedPayment" &&
            !item?.propertyId &&
            !item?.propertyOfferId
        ) {
            dispatch(
                listServiceRequests({ customerId: personaId, customerType: "seller" })
            );
            dispatch(resetServiceRequest());
            redirectUrl = "/seller/profile?" + item?.senderPersona + "s";
        } else if (item?.notificationType === "lawyerMarkPerformed") {
            dispatch(
                listServiceRequests({ customerId: personaId, customerType: "seller" })
            );
            dispatch(resetServiceRequest());
            redirectUrl = "/seller/profile?" + item?.senderPersona + "s";
        } else if (
            item?.notificationType === "vaRejectedJobRequest" ||
            item?.notificationType === "photograprapherCancelledJobRequest" ||
            item?.notificationType === "lawyerCancelledJobRequest" ||
            item?.notificationType === "photograprapherRejectedJobRequest" ||
            item?.notificationType === "vaRejectedJobRequest" ||
            item?.notificationType === "photographerSentAgreePayment" ||
            item?.notificationType === "photographerUploadedPropertyPhoto" ||
            item?.notificationType === "photographerMarkPerformed" ||
            item?.notificationType === "photographerMarkvirtualTourCompleted" ||
            item?.notificationType === "lawyerRejectedJobRequest"
        ) {
            if (item?.senderPersona === "lawyer") {
                redirectUrl =
                    "/third-party/lawyer-profile/" + item?.senderActivePersonaId;
            }
            if (item?.senderPersona === "photographer") {
                redirectUrl =
                    "/third-party/photographer-profile/" + item?.senderActivePersonaId;
            }
            if (item?.senderPersona === "virtualassistant") {
                redirectUrl =
                    "/third-party/virtual-assistant-profile/" +
                    item?.senderActivePersonaId;
            }
        }
        else if(item?.notificationType==="virtualAssistantAgreed"){
            redirectUrl = `/seller/profile${item?.senderPersona ? `?providerType=${item?.senderPersona}` : ""}${item?.serviceRequestID ? `&activeServiceRequest=${item?.serviceRequestID}` : ""}`;
        }
        else if (item?.notificationType === "atomChatNewMessage") {
            if (item?.senderPersona === "lawyer" || item?.senderPersona === "photographer" || item?.senderPersona === "virtualassistant") {
                redirectUrl = `/seller/profile${item?.senderPersona ? `?providerType=${item?.senderPersona}` : ""}${item?.serviceRequestID ? `&activeServiceRequest=${item?.serviceRequestID}` : ""}`;
            }
            else if (item?.senderPersona === "buyer") {
                redirectUrl = `/seller/dashboard${item?.propertyOfferId ? `?propertyOfferId=${item?.propertyOfferId}` : ""}`
            }
        }
        dispatch(sellerRedirectNotification(redirectUrl));

        dispatch(readNotifications(item?.notificationId)).then((r) => {
            if (r?.payload?.data?.status) {
                setOpenNotification(false);
                dispatch(notifications());
                dispatch(messageNotifications({ filters: { notificationType: "atomChatNewMessage" } }));
            }
        });
    };

    useEffect(() => {
        const supportedLanguage = supportedLanguages.find((lang) => lang.code === userLanguage);

        if (supportedLanguage) {
            setIsLangSupported(true);
        }
    }, [userLanguage]);

    const findStatusCheckNotification = () => {
        dispatch(notificationsPropertyStatusCheck({
            all: true,
            filters: {
                notificationType: 'propertyStatusCheck',
                userId: activeUser?.userId,
                read: 0
            }
        })).then((r) => {
            const fetchedData = r?.payload?.data?.result;

            const uniquePropertyIds = new Map()
            const data = fetchedData.filter(item => {
                if (!uniquePropertyIds.has(item.property.propertyId)) {
                    uniquePropertyIds.set(item.property.propertyId, true);
                    return true;
                }
                return false;
            });

            if (data && data.length > 0) {
                data.forEach((item) => {
                    toast(<ProperyCardIsStillActive property={item.property} />,
                        {autoClose: false, className: "is-property-active", toastId: `property-status-check-${item.notificationId}` }
                    )
                })
            }
        })
    }

    useEffect(() => {
        findStatusCheckNotification()
    }, [location]);

    /* Handle focus input search filed */
    const handleFocus = () => {
        setIsFocused(true)
    };

    /* Handle unfocus input search filed */
    const handleBlur = () => {
        setIsFocused(false);
    };

    return (
        <header className="header-seller"s>
            <IconContext.Provider value={colorValue}>
                <nav className="navbar">
                    <div className="clearfix" style={{ width: "100%" }}>
                        <Link to="/seller" className="navbar-logo navbar-logo-seller">
                            <img loading="lazy" src={Logo} alt="" />
                        </Link>

                        <ul className="nav-menu">
                            <li className="nav-item-lang-switcher">
                                <LangSwitcher />
                            </li>

                            <li className="nav-item">
                                <Link to="/seller" className={location?.pathname === "/seller" ? "nav-links active" : "nav-links"}> {t("Начало")} </Link>
                            </li>

                            <li className="nav-item">
                                <Link to="/third-party?lawyers" className={location?.pathname === "/third-party" ? "nav-links active" : "nav-links"}> {t("Професионалисти")} </Link>
                            </li>

                            <li className="nav-item">
                                <Link to="/blog" className={location?.pathname === "/blog" ? "nav-links active" : "nav-links"}> {t("Блог")} </Link>
                            </li>
                        </ul>

                        {!isLangSupported && <form className={isFocused ? "search focus-input-filed" : "search"}>
                            <div className="search-places-auto-complete-container">
                                <CustomPlacesAutocomplete value={validateInput(address) || ""} onChange={setAddress} onSelect={handleSelect}>
                                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                        <div>
                                            <img src={HeaderSearchIcon} className="search-icon" onClick={handleClick} alt="" />

                                            <input className="search-field" {...getInputProps({ placeholder: `${t("Град")}` })}
                                                style={{
                                                    fontSize: "18px",
                                                    fontFamily: userLanguage === "bg" || userLanguage === "ru" ? "Manrope" : 'Poppins',
                                                    color: "rgba(186, 186, 186, 1);",
                                                    border: "none !important",
                                                    outline: "none",
                                                }}
                                                onFocus={handleFocus}
                                                onBlur={handleBlur}
                                            />

                                            <div className="autocomplete-dropdown-container-home">
                                                {loading ? <div>...loading</div> : null}

                                                {suggestions.map((suggestion,key) => {
                                                    return (
                                                        <div  key={key} 
                                                     {...getSuggestionItemProps(suggestion, key)}>
                                                            {suggestion.placePrediction.text.toString()}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    )}
                                </CustomPlacesAutocomplete>
                            </div>
                        </form>}

                        <div className="right-part">
                            {isTabletDevice && (
                                <div className="language-option-tablet">
                                    <LangSwitcher />
                                </div>
                            )}

                            <div className="add-property clearfix add-property-desktop" style={{ cursor: "pointer" }} onClick={handleCreateProperty}>
                                <img loading="lazy" src={AddIconProperty} alt="" />

                                <p className="add-text">{t("Добави имот")}</p>
                            </div>

                            <div className="account-group">
                                <Link to="/seller/my-adds" className="clearfix link">
                                    <img loading="lazy" src={HomeOutline} alt="" />

                                    <p className="title">{t("моите обяви")}</p>
                                </Link>
                            </div>

                            <div className="notification-icon">
                                <Notifications handleNotification={handleClickNotification} setOpenNotification={setOpenNotification} openNotificaration={openNotificaration} messageRef={messageRef} />
                            </div>

                            <div className="notification-icon">
                                <MessageNotification handleNotification={handleClickNotification} setOpenNotification={setOpenNotification2} openNotificaration={openNotificaration2} messageRef={messageRef2} />
                            </div>

                            <div className="bell">
                                <ProfileMenu />
                            </div>

                            <div className="sidebar-menu-icon">
                                <SideBarMenu AddIconProperty={AddIconProperty} handleCreateProperty={handleCreateProperty} />
                            </div>
                        </div>
                    </div>
                </nav>
            </IconContext.Provider>
        </header>
    );
};

export default HeaderSeller;